<template>
    <div class="news-page">
        <PageHeader :items="items" />
        <div class="masonry-container">
            <div v-for="(item, index) in news" :key="index" class="news-card">
                <div class="news-card-body">
                    <h4 class="news-card-title">{{ item.subject }}</h4>
                    <div v-if="item.image" class="news-image mt-1 mb-2">
                        <img :src="item.image.url" alt="" />
                    </div>
                    <div class="news-card-description">
                        <p v-html="item.description"></p>
                    </div>
                </div>
                <div class="news-card-footer">
                    <div class="news-card-details">
                        <p class="news-posted-time">{{ item.createdAt }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import ElementIcon from "@/components/elements/Icon.vue";
import MarkdownIt from "markdown-it";

const md = new MarkdownIt();
export default {
    components: {
        PageHeader,
        ElementIcon,
    },
    watch: {
        "$i18n.locale": function (newVal, oldVal) {
            this.language = this.localeToLanguageMap[newVal];
            this.getNews();
        },
    },
    computed: {
        items() {
            return [
                {
                    text: this.$t("Dental Twin"),
                    to: "/home",
                },
                {
                    text: this.$t("News"),
                    active: true,
                },
            ];
        },
    },
    data() {
        return {
            localeToLanguageMap: {
                en: "english",
                fr: "french",
                de: "german",
                es: "spanish",
            },
            news: "",
            language: "",
        };
    },
    mounted() {
        const currentLocale = this.$i18n.locale;

        // Set the language based on the locale
        this.language = this.localeToLanguageMap[currentLocale]; // Fallback to 'Unknown' if the locale is not mapped

        this.getNews();
    },
    methods: {
        async getNews() {
            let response = await this.$store.dispatch("news/list", {
                language: this.language,
            });
            this.news = response?.data?.data;
        },
    },
};
</script>

<style lang="scss">
.news-image {
    img {
        width: 100%;
        height: 200px;
        object-fit: contain;
    }
}
</style>
